var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "IntegralGoodsList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "运营主体" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "所属类型", options: _vm.ptoductTypeOps },
                  model: {
                    value: _vm.searchParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "type", $$v)
                    },
                    expression: "searchParams.type",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "商品名称" },
                  model: {
                    value: _vm.searchParams.goodsName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "goodsName", $$v)
                    },
                    expression: "searchParams.goodsName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "购买方式", options: _vm.buyTypeOps },
                  model: {
                    value: _vm.searchParams.purchaseType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "purchaseType", $$v)
                    },
                    expression: "searchParams.purchaseType",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属商家" },
                      model: {
                        value: _vm.searchParams.busId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "busId", $$v)
                        },
                        expression: "searchParams.busId",
                      },
                    },
                    "v-select2",
                    _vm.businessParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "所属门店",
                        subjoin: _vm.storeExtra,
                        disabled: _vm.storeDisabled,
                      },
                      model: {
                        value: _vm.searchParams.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "storeId", $$v)
                        },
                        expression: "searchParams.storeId",
                      },
                    },
                    "v-select2",
                    _vm.storeParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "产品状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "最近操作时间",
                    type: "rangedatetimer",
                    maxDate: _vm.maxDate,
                    startTime: _vm.searchParams.beginTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "查看", type: "text", permission: "detail" },
                  on: {
                    click: function ($event) {
                      return _vm.view(scope.row)
                    },
                  },
                }),
                _vm.isShow(scope.row)
                  ? _c(
                      "el-dropdown",
                      {
                        attrs: { trigger: "click" },
                        on: { command: _vm.moreHandle },
                      },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v("\n          更多"),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _vm.isShowPutaway(scope.row)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: {
                                        row: scope.row,
                                        type: "putaway",
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("上架")])]
                                )
                              : _vm._e(),
                            _vm.isShowRemove(scope.row)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: {
                                        row: scope.row,
                                        type: "remove",
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("下架")])]
                                )
                              : _vm._e(),
                            _vm.isShowDelete(scope.row)
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: {
                                        row: scope.row,
                                        type: "delete",
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("删除")])]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }