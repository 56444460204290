import { generateMapByOpts,mapHelper } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '下架',
    value: 0
  }
]
// 所属类型
const ptoductTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '实物商品',
    value: 0
  },
  {
    text: '卡券红包',
    value: 1
  }
]
// 购买方式
const buyTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '积分',
    value: 0
  },
  {
    text: '积分+现金',
    value: 1
  }
]
const statusMap = generateMapByOpts(statusOps)
const ptoductTypeMap = generateMapByOpts(ptoductTypeOps)
const buyTypeMap = generateMapByOpts(buyTypeOps)

// 红包类型
const redPacketType = [
  // {
  //   text: '通用红包',
  //   value: 1
  // },
  // {
  //   text: '商家红包',
  //   value: 2
  // }, 
  {
    text: '门店红包',
    value: 3
  },
  // {
  //   text: '应用红包',
  //   value: 4
  // }
]

const {
  map: redPacketTypeMap,
  setOps: setRedPacketTypeOps
} = mapHelper.setMap(redPacketType)

export {
  statusOps,
  statusMap,
  ptoductTypeOps,
  ptoductTypeMap,
  buyTypeOps,
  buyTypeMap,
  redPacketTypeMap,
  setRedPacketTypeOps,
}
