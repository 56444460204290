<!--
 * @Description: 积分商品列表页面
 * @Author: 小广
 * @Date: 2019-05-30 17:05:53
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-18 12:22:43
 -->
<template>
  <div class="IntegralGoodsList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2
          label="运营主体"
          v-model="searchParams.regionId"
          v-bind="regionParams"
        ></v-select2>
        <v-select
          label="所属类型"
          v-model="searchParams.type"
          :options="ptoductTypeOps"
        ></v-select>
        <v-input label="商品名称" v-model="searchParams.goodsName"></v-input>
        <v-select
          label="购买方式"
          v-model="searchParams.purchaseType"
          :options="buyTypeOps"
        ></v-select>
        <v-select2
          label="所属商家"
          v-model="searchParams.busId"
          v-bind="businessParams"
        ></v-select2>
        <v-select2
          label="所属门店"
          v-model="searchParams.storeId"
          v-bind="storeParams"
          :subjoin="storeExtra"
          :disabled="storeDisabled"
        ></v-select2>
        <v-select
          label="产品状态"
          v-model="searchParams.status"
          :options="statusOps"
        ></v-select>
        <v-datepicker
          label="最近操作时间"
          type="rangedatetimer"
          :maxDate="maxDate"
          :startTime.sync="searchParams.beginTime"
          :endTime.sync="searchParams.endTime"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          text="查看"
          type="text"
          permission="detail"
          @click="view(scope.row)"
        ></v-button>
        <el-dropdown
          trigger="click"
          @command="moreHandle"
          v-if="isShow(scope.row)"
        >
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="isShowPutaway(scope.row)"
              :command="{ row: scope.row, type: 'putaway' }"
            >
              <span>上架</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isShowRemove(scope.row)"
              :command="{ row: scope.row, type: 'remove' }"
            >
              <span>下架</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isShowDelete(scope.row)"
              :command="{ row: scope.row, type: 'delete' }"
            >
              <span>删除</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  editURL,
  getBusinessListURL,
  getStoreListURL,
  deleteURL,
} from "./api";
import {
  statusOps,
  statusMap,
  ptoductTypeOps,
  buyTypeOps,
  buyTypeMap,
} from "./map";
import { regionParams } from "common/select2Params";

export default {
  name: "IntegralGoodsList",
  data() {
    return {
      maxDate: "",
      searchUrl: getListURL,
      statusOps: statusOps,
      ptoductTypeOps: ptoductTypeOps,
      buyTypeOps: buyTypeOps,
      regionParams,
      searchParams: {
        regionId: "",
        busId: "",
        storeId: "",
        goodsName: "",
        purchaseType: undefined,
        type: undefined,
        status: undefined,
        beginTime: "",
        endTime: "",
      },
      headers: [
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "skuSpecification",
          label: "SKU（商品规格）",
        },
        {
          prop: "explanation",
          label: "兑换说明",
        },
        {
          prop: "storeName",
          label: "门店名称",
        },
        {
          prop: "busName",
          label: "商家名称",
        },
        {
          prop: "purchaseType",
          label: "购买方式",
          formatter(row) {
            return buyTypeMap[row.purchaseType];
          },
        },
        {
          prop: "pointPrice",
          label: "积分价格",
        },
        {
          prop: "cashPrice",
          label: "现金价格",
        },
        {
          prop: "stockNumber",
          label: "商品库存",
        },
        {
          prop: "limitNumber",
          label: "限购数量",
        },
        {
          prop: "status",
          label: "产品状态",
          formatter(row) {
            return statusMap[row.status];
          },
        },
        {
          prop: "updateTime",
          label: "最近操作时间",
        },
      ],
      businessParams: {
        searchUrl: getBusinessListURL,
        method: "post",
        request: {
          // 请求参数，
          text: "busName",
          // 回显参数
          value: "id",
        },
        response: {
          text: "name",
          value: "id",
        },
      },
      storeParams: {
        searchUrl: getStoreListURL,
        method: "post",
        request: {
          // 请求参数，
          text: "storeName",
          // 回显参数
          value: "id",
        },
        response: {
          text: "name",
          value: "id",
        },
      },
    };
  },
  computed: {
    storeExtra() {
      let shopId = this.searchParams.busId;
      return { shopId };
    },

    storeDisabled() {
      let busId = this.searchParams.busId;
      let storeDisabled = busId && busId.length > 0;
      return !storeDisabled;
    },
  },
  methods: {
    create() {
      this.$router.push({
        name: "integralProductForm",
        query: {
          type: 0,
        },
      });
    },
    edit(row) {
      this.$router.push({
        name: "integralProductForm",
        query: {
          id: row.id,
          type: row.type,
        },
      });
    },
    view(row) {
      this.$router.push({
        name: "integralProductForm",
        query: {
          id: row.id,
          isView: true,
          type: row.type,
        },
      });
    },
    handlePermission(permission) {
      // 查询按钮权限
      return this.$store.getters.getPermission(permission);
    },
    /* 按钮显示 */
    isShow(row) {
      // 更多按钮的显示
      const show =
        this.isShowPutaway(row) ||
        this.isShowRemove(row) ||
        this.isShowDelete(row);
      return show;
    },
    isShowPutaway(row) {
      return this.handlePermission("putaway") && row.status === 0;
    },
    isShowRemove(row) {
      return this.handlePermission("putaway") && row.status === 1;
    },
    isShowDelete() {
      return this.handlePermission("delete");
    },
    moreHandle(command) {
      // 更多按钮
      let { row, type } = command;
      if (type === "putaway" || type === "remove") {
        this.updateStatus(row);
      } else if (type === "delete") {
        this.deleteProduct(row);
      }
    },
    //上下架
    updateStatus(row) {
      this.$confirm("确定执行该操作？").then((result) => {
        if (!result) {
          return;
        }
        this.$axios
          .post(editURL, {
            id: row.id,
            status: row.status == 0 ? 1 : 0,
          })
          .then((res) => {
            if (res.status === 100) {
              this.$message.success("操作成功");
              this.$refs.list.searchData();
            }
          });
      });
    },
    //删除
    deleteProduct(row) {
      this.$confirm("确定删除该积分商品？").then((result) => {
        if (!result) {
          return;
        }
        this.$axios.get(deleteURL + `?id=${row.id}`).then((res) => {
          if (res.status === 100) {
            this.$message.success("操作成功");
            this.$refs.list.searchData();
          }
        });
      });
    },
  },
};
</script>
